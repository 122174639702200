<template>
    <div>
        <div id = "bottomDiv" >
            <div style="display:inline-block;">
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43042402000031"><img src="../assets/img1.png" style="float:left;"/><p style="float:left;display:inline-block;height:20px;margin: 0px 0px 0px 5px; color:#bbb;">湘公网安备 43042402000031号</p></a>
                <p style="display:inline-block;color:#bbb;line-height:20px;">&nbsp;&nbsp;|&nbsp;&nbsp;</p>
                <a href="https://beian.miit.gov.cn/#/Integrated/index" target = "_Blank" style="color:#bbb;text-decoration:none;">湘ICP备2022007832号-1</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"keepOnRecordFooter"
}
</script>
<style scoped>
#bottomDiv{
	width:100%;
    bottom:0px;
    position:fixed;
	background-color:rgba(0,0,0,0.5);
	text-align:center;
	z-index:9999;
    height: 20px;
    font-size: 12px;
    line-height:20px;
}
</style>