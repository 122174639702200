import axios from 'axios'     //引入
import myVue from '@/main';

let rootUrl = 'https://www.129876.cn/'
// let rootUrl = '/prod'

//这一步的目的是判断出当前是开发环境还是生成环境，方法不止一种，达到目的就行
// if(process.env.NODE_ENV=="development"){
//   rootUrl='http://127.0.0.1:8888/'
// }else{
//   rootUrl='https://www.129876.cn/'
// }

let config = {
  baseURL: rootUrl,
  // timeout: 30000,       //设置最大请求时间
  withCredentials:false
}
const _axios = axios.create(config)
 
/* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
  config => {
      //如果有需要在这里开启请求时的loading动画效果
    //   config.headers.Authorization = getToken;  //添加token,需要结合自己的实际情况添加，
    let token = sessionStorage.getItem('token');
    if(token != undefined && token != ''){
      config.headers.token = token;
    }
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Headers"] = "*";
    config.headers["Access-Control-Allow-Credentials"] = "true";
    config.headers["Access-Control-Allow-Methods"] = "*";
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  err => Promise.reject(err)
);
 
/* 请求之后的操作 */
_axios.interceptors.response.use(
  res => {
    //在这里关闭请求时的loading动画效果
    //这里用于处理返回的结果，比如如果是返回401无权限，可能会是跳回到登录页的操作，结合自己的业务逻辑写
    if(res.data.code != 200){
      if(res.data.message){
        myVue.$message.error(res.data.message);
      }
      if (res.data.code === 20002 ) {//登录状态错误，清除已登录数据
            sessionStorage.clear();
            window.location.href = '';
      }
    }else{
      if(res.data.message != undefined && res.data.message != null && res.data.message != ''){
        myVue.$message.success(res.data.message);
      }
    }
    return res;
  },
  err => {
    if (err) {
      if(err.message.includes('code 400')){
        let data = err.response.data;
        if (data.code) {//登录状态错误，清除已登录数据
            myVue.$message.error(data.message);
            setTimeout(() => {
              sessionStorage.clear();
              window.location.href = '';
            }, 1000);
        }else{
          myVue.$message.error('未知的网络错误!');
        }
      }else{
        //在这里关闭请求时的loading动画效果
        // Toast("请求网络失败")
        myVue.$message.error('网络请求失败!');
      }
    }
    return Promise.reject(err);
  }
);
 
//封装post,get方法
const http = {
  get(url='',params={},header={}){
    if(header['Content-Type']===undefined||header['Content-Type']===''||header['Content-Type']===null){
      header['Content-Type'] = 'application/json;charset=UTF-8';
    }
    return new Promise((resolve, reject) => {
      _axios({
        url,
        params,
        headers:header,
        method: 'GET'
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  },
  post(url='',params={},header={}){
    if(header['Content-Type']===undefined||header['Content-Type']===''||header['Content-Type']===null){
      header['Content-Type'] = 'application/json;charset=UTF-8';
    }
    const formData = new FormData;
    for(var key in params){
      formData.append(key,params[key]);
    }
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data:formData,
        headers:header,
        method: 'POST'
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  },
  filePost(url='',params= {},header={}){
    const formData = new FormData;
    for(var key in params){
      formData.append(key,params[key]);
    }
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data:formData,
        headers:header,
        method: 'POST'
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  },
  downLoadFileGet(url='',params= {},header={}){
    const formData = new FormData;
    for(var key in params){
      formData.append(key,params[key]);
    }
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data:formData,
        headers:header,
        method: 'get',
        responseType:'blob'
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  },
  bodyPost(url='',params={},header={}){
    if(header['Content-Type']===undefined||header['Content-Type']===''||header['Content-Type']===null){
      header['Content-Type'] = 'application/json;charset=UTF-8';
    }
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data:params,
        headers:header,
        method: 'POST'
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  },
  async awaitPost(url='',params={},header={}){
    if(header['Content-Type']===undefined||header['Content-Type']===''||header['Content-Type']===null){
      header['Content-Type'] = 'application/json;charset=UTF-8';
    }
    const formData = new FormData;
    for(var key in params){
      formData.append(key,params[key]);
    }
    let rlt;
    await _axios({
      url,
      data:formData,
      headers:header,
      method: 'POST'
    }).then(res=>{
      rlt = res.data;
    });
    return rlt;
  }
}


export default http