<template>
  <div>
    <el-dropdown placement="bottom" @click.native="btnClick()" :hide-timeout="500">
        <el-button type="primary" style="width:320px">
            上传文件<span v-if="fileSelectNum > 0">({{fileCompleteNum}}/{{fileSelectNum}})</span>
            <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
        </el-button>
        <el-dropdown-menu slot="dropdown" id="uploadDropdown" style="width:300px;max-height:200px;overflow: auto;">
            <el-dropdown-item v-if="fileLoadList&&fileLoadList.length === 0">
                暂未选择文件
            </el-dropdown-item>
            <el-dropdown-item v-for="(item,i) in fileLoadList" :key="item.uid+i" :data="item.uid" :title="item.fileName" @click.native="previewImg(item.src)">
                <span>{{getText(item.fileName)}}</span><div style="display: inline;" @click.stop="deleteFileElement(item.uid)" class="deleteFile"><i class="el-icon-close"></i></div>
                <el-progress :percentage="item.speed" :status="item.speed==100?'success':null"></el-progress>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
    <el-image style="display:none;" id="uploadLoadImg" src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20180804%2Fa1e471584e474de6b1c4f677efc0845d.png&refer=http%3A%2F%2F5b0988e595225.cdn.sohucs.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653043706&t=e74cb2ba91ea1275200faa862bb75ce0" 
            :preview-src-list="loadImg"></el-image>
    <input type="file" id="uploadInput" style="display:none;" @change="selectFile" :multiple = "fileMultiple" ref="uploadInput" :accept="fileAccept" />
  </div>
</template>

<script>
import SparkMD5 from "spark-md5";
import _request from '@/router/homepage_api';

export default {
    name:"uploadFile",
    data() {
        return {
            fileAccept:'image/*',
            fileSelectNum:0,
            fileCompleteNum:0,
            fileLoadList:[],
            loadImg:[],
            isChange:0,
            fileMultiple:'multiple'
        }
    },
    methods: {
        previewImg(src){
            this.loadImg = [src];
            setTimeout(() => {
                document.getElementById('uploadLoadImg').click();
            }, 50);
        },
        btnClick(){
            document.getElementById('uploadInput').multiple = this.fileMultiple;
            document.getElementById('uploadInput').accept = this.fileAccept;
            this.$refs.uploadInput.click();
        },
        selectFile(){
            let fileInput = this.$refs.uploadInput;
            this.fileSelectNum += fileInput.files.length;
            this.isChange += 1;
            let files = fileInput.files;
            for(var i=0;i<files.length;i++){
                let uid = Math.floor(Math.random()*1000000000000);
                let fileData = {};
                let file = files[i];
                fileData['uid'] = uid;
                fileData['fileName'] = file.name;
                fileData['speed'] = 0;
                fileData['src']=window.URL.createObjectURL(file);
                this.fileLoadList.push(fileData);
                let m = this.getFileMd5(file);
                m.then(res=>{
                    this.upload(uid,file,res,0,1024*1024)
                })
            }
            
        },
        getFileMd5(file){
            return new Promise(function(resolve,reject){
                var fileReader = new FileReader();
                var spark = new SparkMD5.ArrayBuffer();
                // 获取文件二进制数据
                fileReader.readAsArrayBuffer(file);
                fileReader.onload = function (e) {
                    spark.append(e.target.result);
                    var md5 = spark.end();
                    resolve(md5);
                };
            })
        }
        ,
        upload(uid,file,md5,start,end){
            let sliceFile = file.slice(start,end);
            let json = {
                "start":start,
                "fileName":file.name,
                "fileMd5":md5,
                "fileSize":file.size
            }
            let param ={
                "json":JSON.stringify(json),
                "file":sliceFile
            }
            let result = _request.upload(param);
            result.then(res=>{
                if(res.code === 200){
                    let status = res.data.status;
                    let {fileId,speed} = res.data;
                    this.fileLoadList.forEach(ele => {
                        let uuid = ele.uid;
                        if(uuid == uid){
                            ele.speed = +((speed*100).toFixed(1));
                            if(fileId){
                                ele.fileId = fileId;
                            }
                        }
                    });
                    if(status == 0){
                        this.upload(uid,file,md5,res.data.start,+res.data.start+(1024*1024));
                    }else{
                        this.fileCompleteNum = this.fileCompleteNum+1;
                    }
                    this.$emit('fileList',this.fileLoadList);
                }
            }).catch(error=>{
                console.error(error);
            });
        },
        getText(text){
            if(text.length > 18){
                return text.substr(0,16)+'...';
            }
            return text;
        },
        deleteFileElement(uid){
            let index = null;
            this.fileLoadList.some((e,i)=>{
                if(e.uid === uid){
                    if(e.fileId){
                        this.fileCompleteNum-=1;
                    }
                    this.fileSelectNum-=1;
                    index=i;
                    return true;
                }
            })
            if(index !== null){
                this.fileLoadList.splice(index,1);
            }
        }
    },
    mounted() {

    },
    created() {
        
    },
}
</script>

<style>
    ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background-color: #99a9bf;
    }
    
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 5px;
        background-color: #d3dce6;
    }
    .deleteFile:hover{
        color:red;
    }
</style>