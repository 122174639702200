<template>
    <div v-loading.fullscreen.lock="fullscreenLoading" >
        <first-home-page :key="timeRefusr" v-if="showHomePage <= 5"></first-home-page>
        <second-home-page :key="timeRefusr" v-if="showHomePage > 5"></second-home-page>
        <transition :name="elFade" v-if="!isLogin">
            <div class="loginBox"  v-show="showLoginBox" ref = "loginBox">
                <el-container style="height:100%">
                    <el-header height = "50px" style="font-size:25px;text-align:center;line-height:50px;color:#fff;">登  录</el-header>
                    <el-main>
                        <el-row :gutter="20" type = "flex" justify= "center" class="row">
                            <el-col :span="5"><div style="font-size:17px;text-align:center;color:#fff;"><span>账号:</span></div></el-col>
                            <el-col :span="13"><el-input class = "inputBox" prop="userName" placeholder="请输入账号" v-model="loginForm.userName" clearable></el-input></el-col>
                        </el-row>
                        <el-row :gutter="20" type = "flex" justify= "center" class="row">
                            <el-col :span="5"><div style="font-size:17px;text-align:center;color:#fff;"><span>密码:</span></div></el-col>
                            <el-col :span="13"><el-input class = "inputBox" @keydown.enter.native="login()" prop="password" placeholder="请输入密码" v-model="loginForm.password" clearable show-password></el-input></el-col>
                        </el-row>
                        <el-row :gutter="20" type = "flex" justify= "center" class="row">
                            <el-col :span="5"><el-button type="primary" @click="login">登录</el-button></el-col>
                            <!-- <el-col :span="5"><el-button >注册</el-button></el-col> -->
                            <el-col :span="5"><el-button @click="showLoginBoxFun">关闭</el-button></el-col>
                        </el-row>
                    </el-main>
                </el-container>
            </div>
        </transition>
        
        <div id = "loginDiv">
            <div id = "loginShowBtn" v-if="!isLogin" @mouseenter="showLoginBtn" @mouseleave="hideLoginBtn" >
                <el-button  plain size="mini" @click="showLoginBoxFun">登录</el-button>
            </div>
            <div v-if="isLogin" >
                <span @click="showInfo = true">
                    <el-avatar :src="userHeadPortrait" >{{loginForm.nickname}}</el-avatar>
                </span>
            </div>
        </div>
        <el-drawer
            title="用户信息"
            :visible.sync="showInfo"
            direction="rtl"
            :size="drawerSize"
            >
            <el-descriptions :column="1" border size="small" id="userInfo">
                <el-descriptions-item label="头像">
                    <el-avatar :src="userHeadPortrait" >{{loginForm.nickname}}</el-avatar>
                    <el-button type="primary"  @click="updateHeadImgBtn()" icon="el-icon-edit" circle size = 'mini'></el-button>
                </el-descriptions-item>
                <el-descriptions-item label="昵称">{{loginForm.nickname}}</el-descriptions-item>
                <el-descriptions-item label="账号">{{loginForm.userName}}</el-descriptions-item>
                <!-- <el-descriptions-item label="密码">
                    <el-button type="primary" size = 'mini'>修改</el-button>
                </el-descriptions-item> -->
                <el-descriptions-item label="图库">
                    <el-button @click="showLibrary(1)" type="primary" size = 'mini'>查看</el-button>
                </el-descriptions-item>
                <el-descriptions-item label="词典">
                    <el-button @click="showLibrary(2)" type="primary" size = 'mini'>查看</el-button>
                </el-descriptions-item>
                <el-descriptions-item label="渲染比例">
                    <el-slider v-model="imgProportion" :show-tooltip="false" :marks="{0:'图',100:'字'}" ></el-slider> 
                </el-descriptions-item>
                <el-descriptions-item label="渲染方式">
                    当前:<span style="margin-right:5px;">{{getXrName(showHomePage)}}</span>
                    <el-button type="primary" size = 'mini' @click="switchRender($event)">切换</el-button>
                    <el-button type="primary" size = 'mini' @click="initData">刷新</el-button>
                </el-descriptions-item>
                <el-descriptions-item label="字体颜色">
                    <el-color-picker v-model="textColor" color-format = 'rgb'></el-color-picker>
                </el-descriptions-item>
                <el-descriptions-item label="操作">
                    <el-button type="primary" size = 'mini' @click="saveSetting($event)">保存设置</el-button>
                    <el-button type="primary" size = 'mini' @click="logout()">注销</el-button>
                </el-descriptions-item>
            </el-descriptions>
        </el-drawer>
        <upload ref="uploadHeadImg" @fileList="updateHeadImg" style="display:none"></upload>
        <el-dialog :title="libraryType===1?'图库':'词典'" 
                    :visible.sync="dialogLibraryVisible" :center="true" 
                    :before-close="closeDialog"
                    width="710px" >
            <div style="height:35px;">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <el-button type="primary" size = 'mini' @click = "addBtn" style="margin-left:10px;">添加</el-button>
                <el-button type="primary" size = 'mini' @click = "operationBtn(1)">分享</el-button>
                <el-button type="primary" size = 'mini' @click = "operationBtn(2)">删除</el-button>
                <el-button type="primary"  size = 'mini' @click = "operationBtn(3)" v-if="libraryType === 1">设为背景</el-button>
            </div>
            <div style="height:450px;overflow:auto;"  v-if="libraryType === 1">
                <el-checkbox-group v-model="checkList" @change="handleCheckedCitiesChange">
                    <div class="img" v-for="item in libraryData" :key="item.libraryId" style="display: inline-block;border: thin solid #00bfff;padding: 2px;margin:2px;width:120px;height:120px;position:relative;">
                        <!-- <span class="demonstration">默认</span> -->
                        <el-checkbox :label="item.libraryId" style="position:absolute;top:0;left: 5px;">&nbsp;</el-checkbox>
                        <el-image :src="item.content" :preview-src-list="libraryImg" style="width:120px;height:120px;">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                    <el-empty description="暂无数据" :image-size="200" v-if="libraryData.length ===0"></el-empty>
                </el-checkbox-group>
            </div>
            <div style="height:450px;overflow:auto;"  v-if="libraryType === 2">
                <el-checkbox-group v-model="checkList" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="item in libraryData" :key="item.libraryId" :label="item.libraryId">{{item.content}}</el-checkbox>
                    <el-empty description="暂无数据" :image-size="200" v-if="libraryData.length ===0"></el-empty>
                </el-checkbox-group>
            </div>
        </el-dialog>
        <el-dialog
            :show-close="false"
            :visible.sync="addImgShow"
            width="400px"
            :before-close="closeUploadLibraryDialog" 
            destroy-on-close
            center
            custom-class="previewDialog"
            >
            <el-card class="box-card" shadow="never">
                <div slot="header" >
                    <span>新增{{libraryType === 1?'图片':'词句'}}</span>
                    <el-button @click="saveData" style="float: right; padding: 3px 0" type="text">保存</el-button>
                </div>
                <div>
                    <span v-if="libraryType === 1">网络链接(每行一条,可一次多条)</span>
                    <span v-else>词句(每行一条,可一次多条)</span>
                    <el-input
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="textarea"
                        show-word-limit
                        resize="none"
                        :autosize="{ minRows: 5, maxRows: 5}"
                        >
                    </el-input>
                    <div style="margin-top:20px" v-if="libraryType===1">
                        <span>本地上传</span>
                        <div style="width:320px;margin:5px auto;">
                            <upload ref="uploadLibraryImg"></upload>
                        </div>
                    </div>
                </div>
            </el-card>
        </el-dialog>
        <thisFooter></thisFooter>
    </div>
</template>
<script>
import keepOnRecordFooter from './keepOnRecordFooter.vue';
import {encryptDes,decryptDes} from '../router/des.js';
import uploadFile from './uploadFile.vue';

export default {
    components: {
        FirstHomePage:resolve => require(['@/views/FirstHomePage.vue'], resolve),
        SecondHomePage:resolve => require(['@/views/SecondHomePage.vue'], resolve),
        thisFooter:keepOnRecordFooter,
        upload:uploadFile
    },
    name:"HomePage",
    data(){
        return{
            fullscreenLoading:false,
            showHomePage:3,
            drawerSize:'30%',
            // 默认冒泡文字
           message:["逍遥江湖，纵横四海","谁知我知你，我知你之深","面如霜下雪，吻如雪上霜","此恨经年深，此情度日久","念念难平，回忆锐起","此时此景，心已僵沉","何以与君识，无言泪千行",
           "真正的大师，永远怀着一颗学徒的心","风萧萧兮易水寒，壮士一去兮不复还","青山不改，绿水长流，我们，后会有期","愿你，所求皆如愿，所行化坦途","所念皆星河","所爱隔山海",
           "如有一味绝境，非历十方生死","天地无心，日月崇光","剑不合鞘，心不容情","死亡如风，常伴吾身","刀斩肉身，心斩灵魂","今天，又是充满希望的一天","此剑，当斩，诸神谢罪",
           "此剑，当判，群魔授首","山有木兮木有枝，心悦君兮君不知","一念通天，神魔无惧","愿得一人心，白首不分离","疾如风，徐如林，侵略如火，不动如山，难知如阴，动如雷霆","临兵斗者皆阵列前行"],
            //默认冒泡图片
           imgs:[
                "https://img0.baidu.com/it/u=1217435672,1194548300&fm=253&fmt=auto&app=138&f=JPEG?w=700&h=476",
                "https://img2.baidu.com/it/u=2992670768,1364040999&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=714",
                "https://img1.baidu.com/it/u=577288658,3492020823&fm=253&fmt=auto&app=120&f=JPEG?w=647&h=500",
                "https://img2.baidu.com/it/u=164799964,1236149823&fm=253&fmt=auto&app=138&f=JPEG?w=450&h=225",
                "https://img2.baidu.com/it/u=3000305515,2722665766&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=372",
                "https://img1.baidu.com/it/u=2497155444,2615411712&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=750",
                "https://img2.baidu.com/it/u=519692993,541009676&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=308" ,
                "https://img2.baidu.com/it/u=3725941135,2448212267&fm=253&fmt=auto&app=120&f=JPEG?w=1422&h=800",
                "https://img0.baidu.com/it/u=3116830784,295934809&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500",
                "https://img0.baidu.com/it/u=3925537905,2811047219&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500",
                "https://img2.baidu.com/it/u=3959483930,904703403&fm=253&fmt=auto&app=138&f=JPEG?w=867&h=500",
                "https://img0.baidu.com/it/u=1532142579,3732318460&fm=253&fmt=auto&app=138&f=JPEG?w=890&h=500",
                "https://img1.baidu.com/it/u=1655312763,3272445252&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500",
                "https://img0.baidu.com/it/u=3821072546,3992758694&fm=253&fmt=auto&app=138&f=JPG?w=1000&h=500",
                'https://img2.baidu.com/it/u=3148847495,2398296706&fm=253&fmt=auto&app=138&f=JPEG?w=673&h=500',
                "https://img0.baidu.com/it/u=2306452225,3147484751&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=315",
                "https://img1.baidu.com/it/u=2148696423,3359218099&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=316",
                "https://img0.baidu.com/it/u=3293463678,758617997&fm=253&fmt=auto&app=120&f=JPEG?w=554&h=284",
                "https://img1.baidu.com/it/u=2897887330,3986946108&fm=253&fmt=auto&app=138&f=JPG?w=500&h=282",
                "https://img2.baidu.com/it/u=23797678,1547639051&fm=253&fmt=auto&app=138&f=JPEG?w=882&h=483",
                "https://img0.baidu.com/it/u=2132726073,2369292505&fm=253&fmt=auto&app=138&f=JPG?w=500&h=278",
                "https://img2.baidu.com/it/u=941742102,915755494&fm=253&fmt=auto&app=138&f=JPEG?w=374&h=258" ,
                "https://img2.baidu.com/it/u=444496543,253880159&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=344"
            ],
            backgroudImgUrl:{},
            loadImg:["https://img2.baidu.com/it/u=444496543,253880159&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=344"],
            // 登录框是否出现
            showLoginBox:false,
            // 登录框表现方式
            elFade:"el-fade-in-linear",
            // 登录表单
            loginForm:{
                userName:'',
                password:'',
                nickname:'user'
            },
            // 是否登录
            isLogin:0,
            // 用户头像
            userHeadPortrait:"",
            // 显示详情
            showInfo:false,
            imgProportion:20,
            textColor:null,
            backImgs:[
                "https://gimg2.baidu.com/image_search/src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20180804%2Fa1e471584e474de6b1c4f677efc0845d.png&refer=http%3A%2F%2F5b0988e595225.cdn.sohucs.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653043706&t=e74cb2ba91ea1275200faa862bb75ce0",
                "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fi0.hdslb.com%2Fbfs%2Farticle%2F5692f5ba82bd4ad3968c4ccddbfa5cb4f2b12d7b.jpg&refer=http%3A%2F%2Fi0.hdslb.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653562355&t=d39b80c73d6da886b1187ca566523b2e",
            ],
            libraryType:1,
            dialogLibraryVisible:false,
            checkList:[],
            checkAll:false,
            isIndeterminate:false,
            addImgShow:false,
            libraryData:[],
            libraryImg:[],
            timeRefusr: new Date().getTime(),
            textarea:''
        }
    },
    methods:{
        // 登录
        async login(){
            let usn = this.loginForm.userName;
            let pwd = this.loginForm.password;
            if(usn === ''){
                this.$notify.error({
                    title: '登录失败',
                    message: '请输入账号！<br>还没有账号?<a href ="https://www.baidu.com" target = "_Blank">去注册</a>',
                    dangerouslyUseHTMLString: true,
                });
                return
            }
            if(pwd === ''){
                // this.$message.error("请输入密码!");
                this.$notify.error({
                    title: '登录失败',
                    message: '请输入密码！'
                });
                return
            }
            // this.$message.info("账号:"+usn+"\n密码:"+pwd);
            // this.$message.success("这只是个彩蛋哟,其实没有登录的啦!!!");
            let requestTime = new Date().getTime();
            let param = {
                'accountNumber':this.loginForm.userName,
                'password':encryptDes(this.loginForm.password,this.loginForm.userName+requestTime)
            }
            let header = {
                'requestTime':requestTime
            }
            this.fullscreenLoading = true;
            this.$http.post('/StartingProject/user/login',param,header).then(res=>{
                this.fullscreenLoading = false;
                if(res.code === 200){
                    this.isLogin = 1;
                    this.showLoginBox = false;
                    sessionStorage.setItem("token",res.data);
                    this.getUserInfo();
                    this.initData();
                }
            }).catch(error=>{
                this.fullscreenLoading = false;
            });
            // this.initData();
        },
        logout(){
            sessionStorage.clear();
            this.loginForm.userName = '';
            this.loginForm.password = '';
            this.isLogin = 0;
            this.showInfo = false;
            window.location.href = '';
        },
        getUserInfo(){
            this.$http.post('/StartingProject/user/getUserInfo').then(res=>{
                if(res.code === 200){
                    this.loginForm.nickname = res.data.nickName;
                    this.userHeadPortrait = res.data.headPortraitUrl;
                    this.loginForm.userName = res.data.accountNumber;
                    if(res.data.renderScale !== null && res.data.renderScale !== undefined){
                        this.imgProportion = res.data.renderScale;
                    }
                    this.textColor = res.data.textColor;
                    this.updateLoginBoxWidth();
                }
            })
        },
        //切换渲染方式
        switchRender(e){
            if(this.showHomePage > 5){
                this.showHomePage = 5;
            }else{
                this.showHomePage = 6;
            }
            let target = e.target
            if (target.nodeName === 'SPAN') {
            target = e.target.parentNode
            }
            target.blur()
        },
        //保存设置
        saveSetting(e){
            let target = e.target
            if (target.nodeName === 'SPAN') {
                target = e.target.parentNode
            }
            target.blur()
            let param = {};
            param.nickName = this.loginForm.nickname;
            if(this.textColor !== null){
                param.textColor = this.textColor;
            }
            param.renderScale = this.imgProportion;
            this.fullscreenLoading = true;
            this.$http.post("/StartingProject/user/saveSetting",param).then(res=>{
                this.fullscreenLoading = false;
            }).catch(err=>{
                this.fullscreenLoading = false;
            })
        },
        // 显示登录按钮
        showLoginBtn(e){
            let tg = e.target;
            tg.style.cursor = "pointer";
            tg.style.opacity = 1;
        },
        // 隐藏登录按钮
        hideLoginBtn(e){
            let tg = e.target;
            tg.style.cursor = "default";
            tg.style.opacity = 0;
        },
        // 修改登录框宽度
        updateLoginBoxWidth(){
            
            let val = window.innerWidth;
            if(this.isLogin == 1){
                if(val < 500){
                    this.drawerSize = '100%';
                }else if(val < 800){
                    this.drawerSize = '80%';
                }else if(val < 1100){
                    this.drawerSize = '50%';
                }else if(val <= 1700){
                    this.drawerSize = '30%';
                }else{
                    this.drawerSize = '20%';
                }
            }else{
                if(val < 300){
                    this.$refs.loginBox.style.width = "100%";
                }else if(val < 500){
                    this.$refs.loginBox.style.width = "80%";
                }else{
                    this.$refs.loginBox.style.width = "500px";
                }
            }
        },
        showLoginBoxFun(){
            let fades = [
                "el-fade-in-linear",
                "el-zoom-in-center",
                "el-zoom-in-top",
            ]
            let fadesIndex = Math.round(Math.random()*(fades.length-1));
            if(!this.showLoginBox){
                this.elFade = fades[fadesIndex];
            }
            this.showLoginBox = !this.showLoginBox;
        },
        updateHeadImg(data){
            let param = {};
            param.fileId = data[data.length - 1].fileId;
            this.$http.post("/StartingProject/user/updateHeadPortrait",param).then(res=>{
                if(res.code === 200){
                    thas.userHeadPortrait=thas.$refs.uploadHeadImg.fileLoadList[cnum-1].src;
                }
            })
        },
        updateHeadImgBtn(){
            this.$refs.uploadHeadImg.fileMultiple = null;
            this.$refs.uploadHeadImg.btnClick();
        },
        showLibrary(type){
            this.libraryData = [];
            let dta = this.getLibraryData(type);
            dta.then(res=>{
                if(res && res.code === 200){
                    this.libraryData = res.data;
                    if(res.data){
                        let ary = [];
                        res.data.forEach(ele => {
                            ary.push(ele.content);
                        });
                        this.libraryImg = ary;
                    }
                }
            })
            this.dialogLibraryVisible = true;
            this.libraryType=type;
        },
        // 加载数据
        async initData(){
            let token = sessionStorage.getItem("token");
            if(token != undefined && token != ''&& token != null){
                this.isLogin = 1;
                this.getUserInfo();
            }    
            try{
                await this.refreshData(1)
                await this.refreshData(2)
                await this.refreshData(3)
            }catch(error){

            }
            let backImgIndex = Math.round(Math.random()*(this.backImgs.length-1));
            this.backgroudImgUrl = {
                backgroundImage:"url("+this.backImgs[backImgIndex]+")"
            }
            this.timeRefusr = new Date().getTime();
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.libraryData.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.libraryData.length;
        },
        handleCheckAllChange(val) {
            if(val){
                let ary = [];
                this.libraryData.forEach(e=>{
                    ary.push(e.libraryId);
                })
                this.checkList = ary;
            }else{
                this.checkList = [];
            }
            this.isIndeterminate = false;
        },
        getLibraryData(type){
            let param = {};
            param.libraryType = type;
            return this.$http.get("/StartingProject/homePage/selectShareOrLibrary",param);
        },
        addBtn(){
            this.addImgShow = true;
        },
        saveData(){
            let json = [];
            if(this.textarea){
                let ary = this.textarea.split('\n');
                ary.forEach(e=>{
                    let map = {};
                    map.content = e;
                    map.libraryType = this.libraryType;
                    json.push(map);
                })
            }
            if(this.libraryType === 1){
                if(this.$refs.uploadLibraryImg.fileSelectNum
                    &&this.$refs.uploadLibraryImg.fileSelectNum !== this.$refs.uploadLibraryImg.fileCompleteNum){
                    this.$message.error("请等待文件上传完成!");
                    return;
                }
                if(this.$refs.uploadLibraryImg.fileSelectNum){
                    this.$refs.uploadLibraryImg.fileLoadList.forEach(e=>{
                        if(e.fileId){
                            let map = {};
                            map.fileId = e.fileId;
                            map.libraryType = this.libraryType;
                            json.push(map);
                        }
                    })
                }
                if(json.length === 0){
                    this.$message.error("请上传文件或填写链接!");
                    return;
                }
                
                
            }else if(this.libraryType === 2){
                if(json.length === 0){
                    this.$message.error("请填写词句!");
                    return;
                }
            }else{
                this.$message.error("未知操作!");
                return;
            }
            let params = {};
            params.json = JSON.stringify(json);
            this.$http.post("/StartingProject/homePage/insertUserLibrary",params).then(res=>{
                if(res && res.code === 200){
                    this.addImgShow = false;
                    this.textarea = '';
                    this.showLibrary(this.libraryType);
                }
            })
        },
        operationBtn(type){
            let tipMsg = "",
            url = "";
            let t = this.libraryType===1?'图片':'词句';
            if(type == 1){
                tipMsg = "请选择需要分享的"+t+"!"
                url = "/StartingProject/homePage/shareLibrary";
            }else if(type == 2){
                tipMsg = "请选择需要删除的"+t+"!"
                url = "/StartingProject/homePage/deleteLibrary";
            }else if(type === 3){
                url = "/StartingProject/homePage/setAsBackground";
                tipMsg = "请选择需要设为背景的图片!";
            }else{
                this.$message.error("未知操作!");
                return;
            }
            if(!this.checkList||this.checkList.length == 0){
                this.$message.error(tipMsg);
                return;
            }
            let param = {};
            let strIds='';
            this.checkList.forEach(ele=>{
                if(strIds){
                    strIds+=",";
                }
                strIds+=ele;
            })
            param.libraryIds = strIds;
            this.fullscreenLoading = true;
            this.$http.post(url,param).then(res=>{
                this.fullscreenLoading = false;
                if(res&&res.code === 200){
                    this.checkAll = false;
                    this.isIndeterminate = false;
                    this.checkList = [];
                    this.showLibrary(this.libraryType)
                    this.initData();
                }
            }).catch(err=>{
                this.fullscreenLoading = false;
            })
        },
        async refreshData(type){
            let param = {};
            param.libraryType = type;
            await this.$http.post("/StartingProject/homePage/selectShareOrLibrary",param).then(res=>{
                if(res&&res.code === 200){
                    if(res.data){
                        if(type === 1){
                            if(res.data.length > 0){
                                this.imgs = [];
                            }else if(sessionStorage.getItem('token')){
                                this.imgs = [];
                            }
                            res.data.forEach((ele,i) => {
                                if(ele.fileId){
                                    this.$http.downLoadFileGet(ele.content).then(gts=>{
                                        let url = window.URL.createObjectURL(gts);
                                        this.imgs.push(url);
                                    })
                                }else{
                                    this.imgs.push(ele.content);
                                }
                            });
                        }else if(type === 2){
                            let ary = [];
                            res.data.forEach(ele => {
                                ary.push(ele.content);
                            });
                            if(ary.length > 0 || sessionStorage.getItem('token')){
                                this.message = ary;
                            }
                        }else if(type === 3){
                            let ary = [];
                            res.data.forEach((ele,i) => {
                                ary.push(ele.content);
                            });
                            if(ary.length > 0){
                                this.backImgs = ary;
                            }
                        }
                    }
                }
            });
        },
        closeDialog(done){
            this.checkAll = false;
            this.handleCheckAllChange(false);
            done();
        },
        closeUploadLibraryDialog(done){
            if(this.$refs.uploadLibraryImg
            &&this.$refs.uploadLibraryImg.fileSelectNum > 0){
                this.$confirm('已有上传的文件,确认关闭吗？')
                .then(_ => {
                    this.textarea = '';
                    done();
                })
                .catch(_ => {
                    
                });
            }else if(this.textarea){
                this.$confirm('已有填写的内容,确认关闭吗？')
                .then(_ => {
                    this.textarea = '';
                    done();
                })
                .catch(_ => {
                    
                });
            }else{
                this.textarea = '';
                done();
            }
        },
        getXrName(index){
            if(index <= 5){
                return '随机移动';
            }else{
                return '气泡';
            }
        }
    },
    mounted(){
        this.showHomePage = Math.round(Math.random()*10);
        let that = this;
        that.updateLoginBoxWidth()
        window.onresize = () => (() => {
            that.updateLoginBoxWidth();
        })();
    },
    created(){
        this.initData();
    }
}
</script>
<style scoped>
#loginDiv{
        position: fixed;
        display:block;
        top:10px;
        right:15px;
        height:30px;
        text-align:center;
        line-height: 30px;
        /* background-color:rgba(0,0,0,0.5); */
        border-radius:3px;
        cursor: default;
    }
    #userInfo{
        overflow: hidden;
    }
    .loginBox{
        position: fixed;
        top:0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        margin: auto;
        height: 300px;
        width: 500px;
        background-color: rgba(0,0,0,0.5);
        z-index: 1999;
        box-shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.5);
        border-radius: 4px
    }
    #loginShowBtn{
        opacity:0;
    }
    .row{
        margin-top:20px;
    }

    /* .el-dialog.previewDialog{
        .el-dialog__header{
            display: none;
        }
        .el-dialog__body {
            padding: 0;
            overflow: unset;
        }
    } */
    .previewDialog.el-dialog__header{
        display: none;
    }
</style>